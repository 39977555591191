import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'adminDocuments',
  initialState: adapter.getInitialState(),
  reducers: {
    setAdminDocuments: (state, { payload }) => adapter.setAll(state, payload),
    setAdminDocument: (state, { payload }) => adapter.setOne(state, payload),
    adminDocumentsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setAdminDocuments, adminDocumentsRemoveAll, setAdminDocument } = slice.actions

export default slice.reducer
