import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'adminDeals',
  initialState: adapter.getInitialState(),
  reducers: {
    setAdminDeal: (state, { payload }) => adapter.setOne(state, payload),
    removeAdminDeal: (state, { payload }) => adapter.removeOne(state, payload),
    setAdminDeals: (state, { payload }) => adapter.setAll(state, payload),
    adminDealsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setAdminDeals, setAdminDeal, removeAdminDeal, adminDealsRemoveAll } = slice.actions

export default slice.reducer
