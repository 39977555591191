export const adminItems = [
  {
    title: 'Dashboard',
    url: '/admin/dashboard',
    icon: 'dashboard',
    activeIcon: 'dashboard-active',
    assocPages: []
  },
  {
    title: 'Tasks',
    url: '/admin/tasks',
    icon: 'tasks',
    activeIcon: 'tasks-active',
    assocPages: []
  },
  {
    title: 'Calendar',
    url: '/admin/calendar',
    icon: 'calendar',
    activeIcon: 'calendar-active',
    assocPages: []
  },
  {
    title: 'Deals',
    url: '/admin/deals',
    icon: 'deals',
    activeIcon: 'deals-active',
    assocPages: [
      '/admin/deal-details',
      '/admin/approve-deal',
      '/admin/company-deal-details',
      '/admin/view-documents',
      '/admin/view-document'
    ]
  },
  {
    title: 'Business Angels',
    url: '/admin/business-angels',
    icon: 'ba',
    activeIcon: 'ba-active',
    assocPages: [
      '/admin/business-angel'
    ]
  },
  {
    title: 'Investors',
    url: '/admin/investors',
    icon: 'investors',
    activeIcon: 'investors-active',
    assocPages: [
      '/admin/investor'
    ]
  },
  {
    title: 'KYC',
    url: '/admin/kyc',
    icon: 'kyc',
    activeIcon: 'kyc-active',
    assocPages: [
      '/admin/edit-profile',
      '/admin/kyc-details'
    ]
  },
  {
    title: 'SPVs',
    url: '/admin/spv',
    icon: 'kyc',
    activeIcon: 'kyc-active',
    assocPages: [
      '/admin/spv-details',
      '/admin/create-spv'
    ]
  },
  {
    title: 'Performance',
    url: '/admin/performance',
    icon: 'dashboard',
    activeIcon: 'dashboard-active',
    assocPages: []
  },
  {
    title: 'Settings',
    url: '/admin/settings',
    icon: 'settings',
    activeIcon: 'settings-active',
    assocPages: []
  }
]

export const complianceItems = [
  {
    title: 'Deals',
    url: '/admin/deals',
    icon: 'deals',
    activeIcon: 'deals-active',
    assocPages: [
      '/admin/deal-details',
      '/admin/approve-deal',
      '/admin/company-deal-details',
      '/admin/view-documents',
      '/admin/view-document'
    ]
  },
  {
    title: 'Settings',
    url: '/admin/settings',
    icon: 'settings',
    activeIcon: 'settings-active',
    assocPages: []
  }
]

export const bankAdminItems = [
  {
    title: 'SPVs',
    url: '/admin/spv',
    icon: 'kyc',
    activeIcon: 'kyc-active',
    assocPages: [
      '/admin/spv-details',
      '/admin/create-spv'
    ]
  },
  {
    title: 'Settings',
    url: '/admin/settings',
    icon: 'settings',
    activeIcon: 'settings-active',
    assocPages: []
  }
]

export const businessAngelItems = [
  {
    title: 'My Profile',
    url: '/profile',
    icon: 'header-person',
    activeIcon: 'business-my-profile-active',
    assocPages: [
      '/edit-profile',
      '/kyc/business',
      '/kyc/nda'
    ]
  },
  {
    title: 'New Deal',
    url: '/add-deal',
    icon: 'business-add-deal',
    activeIcon: 'business-add-deal-active',
    assocPages: []
  },
  {
    title: 'My Deals',
    url: '/my-deals-business',
    icon: 'business-my-deals',
    activeIcon: 'business-my-deals-active',
    assocPages: [
      '/deal-details',
      '/view-documents',
      '/view-document'
    ]
  },
  {
    title: 'Settings',
    url: '/settings',
    icon: 'settings',
    activeIcon: 'settings-active',
    assocPages: []
  }
]

export const investorItems = [
  {
    title: 'My Profile',
    url: '/profile',
    icon: 'header-person',
    activeIcon: 'business-my-profile-active',
    assocPages: [
      '/edit-profile',
      '/kyc/investor',
      '/kyc/verify-profile',
      '/kyc/singapore/step-1',
      '/kyc/singapore/step-2',
      '/kyc/nda'
    ]
  },
  {
    title: 'Deal Flow',
    url: '/deal-flow',
    icon: 'deal-flow',
    activeIcon: 'deal-flow-active',
    assocPages: [
      '/deal',
      '/deal-details'
    ]
  },
  {
    title: 'My Deals',
    url: '/my-deals-investor',
    icon: 'deals',
    activeIcon: 'deals-active',
    assocPages: [
      '/view-documents',
      '/view-document'
    ]
  },
  {
    title: 'Wishlist',
    url: '/wishlist',
    icon: 'watchlist',
    activeIcon: 'watchlist-active',
    assocPages: []
  },
  {
    title: 'SPVs',
    url: '/spv',
    icon: 'kyc',
    activeIcon: 'kyc-active',
    assocPages: [
      '/spv-details'
    ]
  },
  {
    title: 'Performance',
    url: '/performance',
    icon: 'dashboard',
    activeIcon: 'dashboard-active',
    assocPages: []
  },
  {
    title: 'Settings',
    url: '/settings',
    icon: 'settings',
    activeIcon: 'settings-active',
    assocPages: []
  }
]
