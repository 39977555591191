import { useCallbackOne } from 'use-memo-one'
// import { useSelector } from 'react-redux'
// eslint-disable-next-line
// import frowser from 'frowser'

// import { encrypt, decrypt } from 'utils/security/subtleCrypto'

export default function useLog () {
  // const user = useSelector((state) => state.user.value)

  const log = useCallbackOne(async (data) => {
    /*
    try {
      const browser = frowser.getParser(window.navigator.userAgent)
      const [encoded, uid] = await Promise.all([
        encrypt(JSON.stringify({ userId: user?._id, stage: process.env.stage, ...data, browser })),
        encrypt(user?._id || 'ano')
      ])
      await database().set(`${uid}-${Date.now()}`, encoded)
    } catch (e) {
      console.error('log', e)
    }
    */
  }, [])

  const offLoad = async () => {
    /*
    try {
      const out = []
      const keys = await database().keys()
      for (const key of keys) {
        const value = await database().get(key)
        const decoded = await decrypt(value)
        const id = key.split('-')[1]
        out.push({ [id]: JSON.parse(decoded) })
      }
      return out
    } catch (e) {
      console.error('offLoad', e)
      return []
    }
    */
  }

  return { log, offLoad }
}
