import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { DEV } from '.config'

export default async function useRedirect (loading, check) {
  const router = useRouter()
  const user = useSelector((state) => state.user.value)

  const manage = () => {
    // user
    if (loading) window.open(`https://${window.location.hostname}/sign-in`, '_self')

    // admin
    const notAdmin = !user?.roles2?.includes('admin')
    // const compliance = !user?.roles2?.includes('compliance-uk')
    const isAdminPage = router.pathname.includes('admin/')
    if (!DEV && !check && isAdminPage && notAdmin) window.open(`https://${window.location.hostname}/profile`, '_self')
    // if (!DEV && !check && !isAdminPage && compliance) window.open(`https://${window.location.hostname}/admin/deals`, '_self')
  }

  useEffect(() => {
    manage()
  }, [loading, check, router.pathname])
}
