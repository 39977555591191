import { useRef, useState } from 'react'
import { useCallback } from 'use-memo-one'
import { useDispatch, useSelector, batch } from 'react-redux'

import STACK_MAP, { url } from 'utils/fetch/pathMap'
import k from './keys'
import useAbortController from 'utils/hooks/useAbortController'
import { setModal } from 'store/actions/modal'
import API_TO_URL from './apiToUrl'
import getOptions from './options'
import fetcher from './fetcher'
import { removeAdminDeal } from 'store/actions/adminDeals'
import { isAdmin, isInvestor } from 'utils/utils'
import { removeAdminPerformanceDeal } from 'store/actions/adminPerformance'
import { removeDealFlowDeal } from 'store/actions/dealFlow'
import { removeInvestorDeal } from 'store/actions/investorDeals'
import { removeInvestorPerformanceDeal } from 'store/actions/investorPerformance'
import { removeBusinessDeal } from 'store/actions/businessDeals'
import { removeAdminUser } from 'store/actions/users'
import { removeInvestor } from 'store/actions/investors'
import { removeBusiness } from 'store/actions/businesses'
import useLog from 'utils/debug'
import { DEBUG } from '.config'

export default function useApi () {
  const { getSignal } = useAbortController()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.value)
  const _key = useRef()
  const [loading, setLoading] = useState(false)
  const { log } = useLog()
  // const active = useActiveTab()

  const API = useCallback((body) => {
    const signal = getSignal()

    return async (key, isPrivate, id, noError, throwOnError) => {
      // if (!active) return
      log({ category: 'request-start', message: key })
      setLoading(true)
      if (isPrivate && !user?.tokenId) return
      _key.current = key
      const u = API_TO_URL[key]
      const stackId = STACK_MAP[u]
      const fullUrl = `${url(stackId)}${u}`
      const options = getOptions('POST', body, k[stackId], signal)
      const response = await fetcher({ fullUrl, options, dispatch, throwOnError })
      if (DEBUG) console.info('response', key, response)

      // const no = (await database().get(key) || 0)
      // avoid loops
      if (response?.error === 'Unauthorized') return { response: null, error: null }
      setLoading(false)

      if (response?.error && !noError && !throwOnError) {
        log({ category: 'request-end', message: `${key}: ${response?.error}` })
        dispatch(setModal({ error: response?.error }))
        return { response: null, error: response?.error }
      }

      if (response?.error && noError && throwOnError) {
        log({ category: 'request-end', message: `${key}: ${response?.error}` })
        const isDealDeleted = response?.error === 'Deal was deleted, nothing to do'
        const isDuplicated = response?.error.includes('duplicate')
        const isUserDeleted = response?.error === 'User was deleted, nothing to do'

        if (!isDealDeleted && !isUserDeleted && !isDuplicated) throw new Error('Aborted task')
        if (isDealDeleted) deletedDealRemoval(user, body, dispatch)
        if (isUserDeleted) deleteUserRemoval(user, body, dispatch)

        return { response: null, error: null }
      }

      if (response?.response && !response?.error) {
        log({ category: 'request-end', message: `${key}: ok` })
        /*
        Hub.dispatch('events', {
          event: 'errorCount',
          action: 'del',
          body: { key }
        })
        */

        return { response: response.response, error: null }
      }

      return { response: response?.response, error: response?.error }
    }
  }, [])

  return {
    API,
    loading
  }
}

function deletedDealRemoval (user, body, dispatch) {
  const admin = isAdmin(user)
  const investor = isInvestor(user)

  batch(() => {
    if (admin) {
      dispatch(removeAdminDeal(body?.dealId))
      dispatch(removeAdminPerformanceDeal(body?.dealId))
    }

    if (investor) {
      dispatch(removeDealFlowDeal(body?.dealId))
      dispatch(removeInvestorDeal(body?.dealId))
      dispatch(removeInvestorPerformanceDeal(body?.dealId))
    } else {
      dispatch(removeBusinessDeal(body?.dealId))
    }
  })
}

function deleteUserRemoval (user, body, dispatch) {
  const admin = isAdmin(user)

  if (admin) {
    batch(() => {
      dispatch(removeAdminUser(body?.userId))
      dispatch(removeInvestor(body?.userId))
      dispatch(removeBusiness(body?.userId))
    })
  }
}
