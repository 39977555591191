import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'businessInvestments',
  initialState: adapter.getInitialState(),
  reducers: {
    setBusinessInvestment: (state, { payload }) => adapter.setOne(state, payload),
    removeBusinessInvestment: (state, { payload }) => adapter.removeOne(state, payload),
    setBusinessInvestments: (state, { payload }) => adapter.setAll(state, payload),
    removeAllBusinessInvestments: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setBusinessInvestment, removeBusinessInvestment, setBusinessInvestments, removeAllBusinessInvestments } = slice.actions

export default slice.reducer
