import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false
}

export const slice = createSlice({
  name: 'enter',
  initialState,
  reducers: {
    setEnter: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setEnter } = slice.actions

export default slice.reducer
