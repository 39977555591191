export const DEV = false
export const NO_CACHE = false

// upgrade message (not implemented)
export const UPGRADE = false
export const UPGRADE_MESSAGE = 'We are undergoing an upgrade, please check back later.'
export const DEBUG = true

// urls
export const MAIL_CHIMP_URL = '//shareline.us2.list-manage.com/subscribe/post?u=ab1fd3b5cee097e088e25b599&id=800f66f208'
export const CALENDLY = process.env.stage === 'dev' ? 'https://calendly.com/talaikis' : 'https://calendly.com/tdsh'
export const STAGE = process.env.stage

// bucket
export const BUCKET = (() => process.env.bucket)()

// key and security definitions
export const AUTO_SIGN_OUT_SECONDS = 60 * 60 * 48 * 1000 // 48 hours

export const isCloudFront = () => !window.location.hostname.includes('shareline.co') && !DEV && !window.location.hostname.includes('localhost')
export const isLocal = () => window.location.hostname.includes('localhost')

const CF_PREFIX = {
  dev: 'd3u5lj7gamroae',
  stag: 'dtasb09d1kbo5',
  prod: 'd2ujppgw0qbl08'
}
export const CLOUDFRONT = `https://${CF_PREFIX[process.env.stage]}.cloudfront.net/`

export const TINY_MCE_API_KEY = 'xe0a8vluh4szrz3mqj9dxsotcig44ivzhsdy7zlvlc012c5t'
export const DEAL_LOGOS_CF = 'https://d2rk39pcdshwj.cloudfront.net/'

export const SIGNATURE_ARN = 'gen-signature2'
export const NDA_ARN = 'nda2'

export const SOCIAL = {
  google: {
    id: '679677550154-bpsh6h1u0t1rhdtt76os8hrtt3n0bt5n.apps.googleusercontent.com'
  },
  linkedIn: {
    id: '78t9lcvn8qp1rq'
  }
}

export const WS_ENDPOINT = `wss://api9.shareline.net/${process.env.stage}`

export const ENC_PASSWORD = process.env.enc
