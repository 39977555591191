const getOptions = (method, body, key, signal) => {
  return {
    method,
    signal,
    headers: {
      'x-api-key': key,
      Accept: 'application/json',
      'X-SCP': process.env.scp,
      'Content-Type': 'application/json'
    },
    isBase64Encoded: false,
    body: JSON.stringify(body)
  }
}

export default getOptions
