const SECRET = Symbol.for('secret')

// eslint-disable-next-line
const curry = (fn) => (...args) => args.length < fn.length ? curry(fn).bind(null, ...args) : fn.call(null, ...args)

function Const (x) {
  this[SECRET] = x
}

Const.of = (x) => new Const(x)

Object.assign(Const.prototype, {
  map (_) { return this },
  chain (_) { return this },
  unwrap () { return this[SECRET] }
})

export const set = curry((key, value, obj) => obj.map((x) => x[key] === value))
const get = curry((key, obj) => obj.unwrap()[key])
export const getVal = (k) => get(k)

export default Const
