import { format } from 'light-date'
import { sort } from 'fast-sort'

export const gbDate = (d) => {
  if (!d) return
  const date = new Date(d)
  return format(date, '{MM}/{dd}/{yyyy}')
}

export const gbDateWithTime = (d) => {
  if (!d) return
  const date = new Date(d)
  return format(date, '{MM}/{dd}/{yyyy} | {HH}:{mm}')
}

export const nowToString = () => {
  const d = new Date()
  return d.toString()
}

function daysBetween (startDate, endDate) {
  const oneDay = 1000 * 60 * 60 * 24

  const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
  const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())

  return (start - end) / oneDay
}

export const remainingDays = (endDate) => {
  try {
    const startDate = new Date()
    const endDt = new Date(endDate)
    return daysBetween(startDate, endDt)
  } catch (_) {
    return 'n/a'
  }
}

export const sortByDate = (array, key) => {
  try {
    if (array?.length) {
      return sort(array).desc((u) => new Date(u?.[key]))
    }
  } catch (e) {
    console.error('sortByDate', e.message)
  }
  return array
}

export const sortByDateAndKey = (array, key1, key2) => {
  try {
    const fallBack = '1970-11-16T20:27:18.634Z'
    return sort(array).by([
      { desc: (u) => new Date(u?.[key1] || fallBack) },
      { asc: (u) => new Date(u?.[key2]) }
    ])
  } catch (_) {
    return array
  }
}

export const stringDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return new Date(`${year}-${month}-${day}`)
}
