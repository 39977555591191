import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setSchedule: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setSchedule } = slice.actions

export default slice.reducer
