import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const slice = createSlice({
  name: 'dealWarning',
  initialState,
  reducers: {
    setDealWarning: (state, { payload }) => {
      state.value = !payload ? payload : state.value ? { ...state.value, ...payload } : payload
    }
  }
})

export const { setDealWarning } = slice.actions

export default slice.reducer
