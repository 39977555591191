import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import { SITE_URL, SITE_TITLE, DEFAULT_OG_TYPE, DEFAULT_DESCRIPTION, DEFAULT_IMAGE, TWITTER_HANDLE } from '.config/meta'
import Services from 'services'

const Meta = ({ title, path, image, description, type }) => {
  const pageProps = {
    path,
    image: typeof image === 'string' ? image : DEFAULT_IMAGE,
    fullTitle: `${SITE_TITLE}: ${title}`,
    fullUrl: `${SITE_URL}${path}`,
    description: typeof description === 'string' ? description : DEFAULT_DESCRIPTION,
    type: typeof type === 'string' ? type : DEFAULT_OG_TYPE
  }

  return (
    <>
      <Head>
        <title>{ pageProps.fullTitle }</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta charSet="UTF-8"/>
        <meta name="author" content="Shareline" />
        <meta name="robots" content="index, follow" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#f9f9fc" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name = "apple-mobile-web-app-capable" content="yes" />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content={SITE_TITLE} />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;800&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://d43jxwcubpn38.cloudfront.net" />
        <link rel='canonical' href={pageProps.fullUrl} />
        <meta name="title" content={title} />
        <meta name='description' content={pageProps.description} />
        <meta property='og:title' content={pageProps.fullTitle} />
        <meta property='og:url' content={pageProps.fullUrl} />
        <meta property='og:type' content={pageProps.type} />
        <meta property='og:description' content={pageProps.description} />
        <meta property='og:image' content={pageProps.image} />
        { TWITTER_HANDLE ? <meta property='twitter:creator' content={TWITTER_HANDLE} /> : null }
        <meta property='og:locale' content={pageProps.locale} />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:title' content={pageProps.fullTitle} />
        <meta property='twitter:description' content={pageProps.description} />
        <meta property='twitter:url' content={pageProps.fullUrl} />
        <meta property='twitter:image:src' content={pageProps.image} />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/s/app-icons/60x60.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/s/app-icons/120x120.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/s/app-icons/152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/s/app-icons/180x180.png" />
        <link rel="icon" type="image/png" sizes="22x22" href="/s/app-icons/22x22.png" />
        <link rel="icon" type="image/png" sizes="50x50" href="/s/app-icons/50x50.png" />
        <link rel="icon" type="image/png" sizes="60x60" href="/s/app-icons/60x60.png" />
        <link rel="icon" type="image/png" sizes="80x80" href="/s/app-icons/80x80.png" />
        <link rel="icon" type="image/png" sizes="120x120" href="/s/app-icons/120x120.png" />
        <link rel="icon" type="image/png" sizes="144x144" href="/s/app-icons/144x144.png" />
        <link rel="icon" type="image/png" sizes="152x152" href="/s/app-icons/152x152.png" />
        <link rel="icon" type="image/png" sizes="512x512" href="/s/app-icons/512x512.png" />
      </Head>
      <Services />
    </>
  )
}

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string
}

export default Meta
