import { store } from '../index'
import { adapter as adminDeals } from 'store/actions/adminDeals'
import { adapter as investors } from 'store/actions/investors'
import { adapter as businesses } from 'store/actions/businesses'
import { adapter as users } from 'store/actions/users'
import { adapter as admins } from 'store/actions/admins'
import { adapter as documents } from 'store/actions/documents'
import { adapter as dealDocuments } from 'store/actions/dealDocuments'
import { adapter as dealFlow } from 'store/actions/dealFlow'
import { adapter as businessDeals } from 'store/actions/businessDeals'
import { adapter as investorDeals } from 'store/actions/investorDeals'
import { adapter as adminDocuments } from 'store/actions/adminDocuments'
import { adapter as adminInvestments } from 'store/actions/adminInvestments'
import { adapter as adminPerformance } from 'store/actions/adminPerformance'
import { adapter as investorPerformance } from 'store/actions/investorPerformance'
import { adapter as investorInvestments } from 'store/actions/investorInvestments'
import { adapter as adminInterests } from 'store/actions/adminInterests'
import { adapter as watchList } from 'store/actions/watchList'
import { adapter as jobs } from 'store/actions/jobs'
import { adapter as adminSpv } from 'store/actions/adminSpv'
import { adapter as spv } from 'store/actions/spv'
import { adapter as bankAdmin } from 'store/actions/bankAdmin'
import { adapter as memberDocument } from 'store/actions/memberDocument'
import { adapter as spvInvestor } from 'store/actions/spvInvestor'
import { adapter as adminSpvInvestor } from 'store/actions/adminSpvInvestor'
import { adapter as notifications } from 'store/actions/notifications'

export function selectAll (key) {
  try {
    const selectors = ADAPTER_MAP[key].getSelectors((state) => state?.[STATE_MAP[key]])
    return selectors.selectAll(store.getState())
  } catch (e) {
    return []
  }
}

export const selectById = (key, id) => {
  try {
    const selectors = ADAPTER_MAP?.[key]?.getSelectors((state) => state?.[STATE_MAP?.[key]])
    return selectors?.selectById(store.getState(), id)
  } catch (e) {
    return null
  }
}

const ADAPTER_MAP = {
  adminDeals,
  investors,
  businesses,
  users,
  admins,
  documents,
  dealDocuments,
  dealFlow,
  businessDeals,
  investorDeals,
  adminDocuments,
  adminInvestments,
  adminPerformance,
  investorPerformance,
  investorInvestments,
  adminInterests,
  watchList,
  jobs,
  adminSpv,
  spv,
  bankAdmin,
  memberDocument,
  spvInvestor,
  adminSpvInvestor,
  notifications
}

const STATE_MAP = {
  adminDeals: 'adminDeals',
  investors: 'investors',
  businesses: 'businesses',
  users: 'users',
  admins: 'admins',
  documents: 'documents',
  dealDocuments: 'dealDocuments',
  dealFlow: 'dealFlow',
  businessDeals: 'businessDeals',
  investorDeals: 'investorDeals',
  adminDocuments: 'adminDocuments',
  adminInvestments: 'adminInvestments',
  adminPerformance: 'adminPerformance',
  investorPerformance: 'investorPerformance',
  investorInvestments: 'investorInvestments',
  adminInterests: 'adminInterests',
  watchList: 'watchList',
  jobs: 'jobs',
  adminSpv: 'adminSpv',
  spv: 'spv',
  bankAdmin: 'bankAdmin',
  memberDocument: 'memberDocument',
  spvInvestor: 'spvInvestor',
  adminSpvInvestor: 'adminSpvInvestor',
  notifications: 'notifications'
}
