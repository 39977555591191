export function register (swPath, options) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(swPath || '/service-worker.js', options)
        .then(function (registration) {
          return registration
        })
        .catch(function (registrationError) {
          console.error('SW registration failed: ', registrationError)
        })
    })
  }
}

export function unregister () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
}

export function ServiceWorkerUnregister () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      if (!registrations.length) {
        console.info('✅ No serviceWorker registrations found.')
        return
      }
      for (const registration of registrations) {
        registration.unregister().then((boolean) => {
          // result of the unregister
          if (boolean) {
            console.info('✅ Successfully unregistered')
          } else {
            console.error('🔥 Failed to unregister')
          }
          /*

          // additional info
          if (registration.installing) {
            console.info('SW_Registration.installing.scriptURL', registration.installing.scriptURL)
          }
          if (registration.waiting) {
            console.info('SW_Registration.installing.scriptURL', registration.installing.scriptURL)
          }
          if (registration.active) {
            console.info('SW_Registration.active.scriptURL', registration.active.scriptURL)
          }
          if (registration.scope) {
            console.info('SW_registration.scope', registration.scope)
          }
          */
        })
      }

      // reload the whole page
      if (undefined !== window) {
        // window.location.reload()
      }
    })
  } else {
    console.info('✅ Service worker is not supported in this browser')
  }
}
