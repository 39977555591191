import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'watchList',
  initialState: adapter.getInitialState(),
  reducers: {
    setWatchListItem: (state, { payload }) => adapter.setOne(state, payload),
    removeWatchListItem: (state, { payload }) => adapter.removeOne(state, payload),
    setWatchList: (state, { payload }) => adapter.setAll(state, payload),
    removeAllWatchList: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setWatchListItem, removeWatchListItem, setWatchList, removeAllWatchList } = slice.actions

export default slice.reducer
