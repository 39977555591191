import localForage from 'localforage'

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'shareline',
  version: 1.0,
  storeName: 'keyvaluepairs',
  description: 'Shareline offline storage'
})

function storage (name) {
  const db = localForage.createInstance({
    name
  })

  return {
    db,
    getItem: db.getItem,
    setItem: db.setItem,
    removeItem: db.removeItem,
    keys: db.keys,
    clear: db.clear
  }
}

export default storage
