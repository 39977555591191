import React, { memo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import BackButton from 'components/layouts/header/backButton'
import { RALEWAY_21 } from 'theme/theme'

const TitleWrapper = styled.div`
  display: flex;
  ${props => props.noCenter ? '' : 'align-items: center;'}
`

const Title = styled.h3`
  ${RALEWAY_21}

  position: relative;
  color: #646770;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  font-weight: 800;
  z-index: 9999;

  @media (max-width: 767px) {
    max-width: 130px;
    white-space: normal;
    text-align: center;
    font-weight: 800;
  z-index: 9999;
  }
`

const Rectangle = styled.div`
  position: relative;
  display: flex;
  min-width: 35px;
  width: 50%;
  height: 3px;
  background: #e4af27;
  margin-top: 6px;

  @media (max-width: 767px) {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
`

function SuperTitle ({ title, isBack, backUrl, Icon }) {
  return (
    <TitleWrapper noCenter={Icon || false}>
      { isBack ? <BackButton backUrl={backUrl} /> : null }
      <Title isBack={isBack} data-test="title">
        { title }
        <Rectangle />
      </Title>
      { Icon || null }
    </TitleWrapper>
  )
}

export default memo(SuperTitle)

SuperTitle.propTypes = {
  title: PropTypes.string,
  backUrl: PropTypes.string,
  headerDeal: PropTypes.bool,
  Icon: PropTypes.any,
  isBack: PropTypes.bool
}
