const ignorePages = [
  '/forgot-password',
  '/sign-up-business',
  '/sign-up-next',
  '/sign-up-investor',
  '/sign-up',
  '/tos',
  '/privacy-policy',
  '/message',
  '/verify-email',
  '/confirm',
  '/404',
  '/500',
  '/events',
  '/disclaimer',
  '/about-us',
  '/founders-form',
  '/employees-form',
  '/risk-warnings',
  '/charts',
  '/charts-dark',
  '/pdf',
  '/schedule',
  '/schedule-dark',
  '/media',
  '/linkedin'
]

export default function checker (pathName) {
  return ignorePages.some((i) => pathName.includes(i) || pathName === i)
}
