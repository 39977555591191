import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const dealsSlice = createSlice({
  name: 'regulatedStatus',
  initialState,
  reducers: {
    setRegulatedStatus: (state, { payload }) => {
      state.value = state.value ? { ...state.value, ...payload } : payload
    }
  }
})

export const { setRegulatedStatus } = dealsSlice.actions

export default dealsSlice.reducer
