import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    step: 0
  }
}

export const slice = createSlice({
  name: 'createSpv',
  initialState,
  reducers: {
    setCreateSpv: (state, { payload }) => {
      state.value = !payload ? { step: 0 } : state.value ? { ...state.value, ...payload } : payload
    }
  }
})

export const { setCreateSpv } = slice.actions

export default slice.reducer
