import React, { memo } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import { NotificationsBox, ProfileBox } from 'components/layouts/components'
import { isCompliance, isBankAdmin } from 'utils/utils'

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 999px) {
    margin-right: -20px;
  }

  @media (max-width: 767px) {
    flex: 1;
  }
`

function Profile () {
  const user = useSelector((state) => state.user.value)
  const compliance = isCompliance(user)
  const bank = isBankAdmin(user)

  return (
    <Box>
      { compliance || bank ? null : <NotificationsBox /> }
      <ProfileBox />
    </Box>
  )
}

export default memo(Profile)
