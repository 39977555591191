import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'jobs',
  initialState: adapter.getInitialState(),
  reducers: {
    setJob: (state, { payload }) => adapter.upsertOne(state, payload),
    removeJob: (state, { payload }) => adapter.removeOne(state, payload),
    setJobs: (state, { payload }) => adapter.setAll(state, payload),
    removeAllJobs: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setJob, removeJob, setJobs, removeAllJobs } = slice.actions

export default slice.reducer
