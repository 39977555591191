import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'businessDeals',
  initialState: adapter.getInitialState(),
  reducers: {
    setBusinessDeal: (state, { payload }) => adapter.setOne(state, payload),
    removeBusinessDeal: (state, { payload }) => adapter.removeOne(state, payload),
    setBusinessDeals: (state, { payload }) => adapter.setAll(state, payload),
    businessDealsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setBusinessDeals, businessDealsRemoveAll, setBusinessDeal, removeBusinessDeal } = slice.actions

export default slice.reducer
