import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'adminSpvInvestor',
  initialState: adapter.getInitialState(),
  reducers: {
    setAdminSpvInvestor: (state, { payload }) => adapter.setOne(state, payload),
    removeAdminSpvInvestor: (state, { payload }) => adapter.removeOne(state, payload),
    setAdminSpvInvestors: (state, { payload }) => adapter.setAll(state, payload),
    adminSpvInvestorsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setAdminSpvInvestor, removeAdminSpvInvestor, setAdminSpvInvestors, adminSpvInvestorsRemoveAll } = slice.actions

export default slice.reducer
