import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { store } from '../'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'investorPerformance',
  initialState: adapter.getInitialState(),
  reducers: {
    setInvestorPerformanceDeal: (state, { payload }) => adapter.setOne(state, payload),
    removeInvestorPerformanceDeal: (state, { payload }) => adapter.removeOne(state, payload),
    setInvestorPerformanceDeals: (state, { payload }) => adapter.setAll(state, payload),
    investorPerformanceDealsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setInvestorPerformanceDeal, removeInvestorPerformanceDeal, setInvestorPerformanceDeals, investorPerformanceDealsRemoveAll } = slice.actions

export default slice.reducer

const getItems = (items) => {
  try {
    return Object.values(items?.entities)
  } catch (e) {
    return []
  }
}

export const setInvestorPerformance = () => {
  const state = store.getState()
  const { investorDeals } = state
  const deals = getItems(investorDeals)

  return (dispatch) => {
    const filtered = deals?.length
      ? deals.filter((i) => (i?.deal?.dealStatus === 'done' && i?.invested) || (i?.deal?.dealStatus === 'exited' && i?.invested))
      : []

    if (filtered?.length) dispatch(setInvestorPerformanceDeals(filtered))
  }
}
