import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'adminSpv',
  initialState: adapter.getInitialState(),
  reducers: {
    setAdminSpv: (state, { payload }) => adapter.setOne(state, payload),
    removeAdminSpv: (state, { payload }) => adapter.removeOne(state, payload),
    setAdminSpvs: (state, { payload }) => adapter.setAll(state, payload),
    adminSpvsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setAdminSpv, removeAdminSpv, setAdminSpvs, adminSpvsRemoveAll } = slice.actions

export default slice.reducer
