import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const derivateSlice = createSlice({
  name: 'derivatives',
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setData } = derivateSlice.actions

export default derivateSlice.reducer
