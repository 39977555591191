// use this site url as only one source for meta values
export const SITE_URL = 'https://shareline.co'
export const SITE_TITLE = 'Shareline'
export const COMPANY = 'Shareline'
export const DEFAULT_OG_TYPE = 'company'
export const DEFAULT_DESCRIPTION = 'Shareline: Invest in Private Tech Companies'
export const DEFAULT_IMAGE = `${SITE_URL}/s/images/og-image.png`
export const TWITTER_HANDLE = '@ashpuri'
export const META = {
  home: { title: 'Invest in Private Tech Companies', description: 'Shareline enables experts and newcomers alike to invest in the late stage private technology companies they love. Join our community today!', path: '/' },
  aboutUs: { title: 'About Us', description: 'About Us', path: '/about-us' },
  spv: { title: 'SPVs', description: 'SPV', path: '/spv' },
  adminSpv: { title: 'SPVs Overview', description: 'SPVs Overview', path: '/admin/spv' },
  spvDetails: { title: 'SPV Details', description: 'SPV Details', path: '/spv-details' },
  adminSpvDetails: { title: 'SPV Details', description: 'SPV Details', path: '/admin/spv-details' },
  createSpv: { title: 'Create SPV', description: 'Create new SPV', path: '/admin/create-spv' },
  confirm: { title: 'Confirm Account', description: 'Confirm Account.', path: '/confirm' },
  adminTasks: { title: 'Tasks', description: 'Tasks.', path: '/admin/tasks' },
  adminSettings: { title: 'Settings', description: 'Settings.', path: '/admin/settings' },
  dealFlow: { title: 'Deal Flow', description: 'Deal Flow.', path: '/deal-flow' },
  editProfile: { title: 'Edit Profile', description: 'Edit Profile.', path: '/edit-profile' },
  forgotPassword: { title: 'Forgot Password', description: 'Forgot Password.', path: '/forgot-password' },
  myDealsBusiness: { title: 'My Deals', description: 'My Deals.', path: '/my-deals-business' },
  myDealsInvestor: { title: 'My Deals', description: 'My Deals.', path: '/my-deals-investor' },
  nda: { title: 'Non-Disclosure Agreement', description: 'Non-Disclosure Agreement.', path: '/kyc/nda' },
  performance: { title: 'Performance', description: 'Performance.', path: '/performance' },
  adminPerformance: { title: 'Performance', description: 'Performance.', path: '/admin/performance' },
  disclaimer: { title: 'Disclaimer', description: 'Disclaimer.', path: '/disclaimer' },
  tos: { title: 'Terms of Services', description: 'Terms of Services.', path: '/tos' },
  privacyPolicy: { title: 'Privacy Policy', description: 'Privacy Policy.', path: '/privacy-policy' },
  addDeal: { title: 'Add Deal', description: 'Add Deal.', path: '/add-deal' },
  notifications: { title: 'Notifications', description: 'Notifications.', path: '/notifications' },
  profile: { title: 'Profile', description: 'Profile.', path: '/profile' },
  deal: { title: 'Deal', description: 'Deal.', path: '/deal' },
  riskWarnings: { title: 'Risk Warnings', description: 'Risk warnings.', path: '/risk-warnings' },
  settings: { title: 'Settings', description: 'Settings.', path: '/settings' },
  signInBusiness: { title: 'Business Angel Sign In', description: 'Business Angel Sign In.', path: '/sign-in-business' },
  signInInvestor: { title: 'Sign In', description: 'Investor Sign In.', path: '/sign-in' },
  verifyEmail: { title: 'Verify Email', description: 'Verify Email.', path: '/verify-email' },
  approveDeal: { title: 'Approve Deal', description: 'Approve Deal.', path: '/admin/approve-deal' },
  dealDetails: { title: 'Deal Details', description: 'Deal Details', path: '/deal-details' },
  adminDealDetails: { title: 'Deal Details', description: 'Deal Details', path: '/admin/deal-details' },
  adminCompanyDealDetails: { title: 'Deal Details', description: 'Deal Details', path: '/admin/company-deal-details' },
  signature: { title: 'Signature', description: 'Signature.', path: '/kyc/signature' },
  message: { title: 'Message', description: 'Message.', path: '/message' },
  redirect: { title: 'Redirecting...', description: 'Redirecting...', path: '/mobile' },
  employee: { title: 'Employees form', description: 'Employees form.', path: '/employees-form' },
  founder: { title: 'Founders form', description: 'Founders form.', path: '/founders-form' },
  editKYC: { title: 'Edit profile', description: 'Edit profile.', path: '/admin/edit-profile' },
  investorVerifyProfile: { title: 'Verify Profile', description: 'Verify profile.', path: '/kyc/investor' },
  signUpBusiness: { title: 'Sign Up for Business', description: 'Sign Up for Business.', path: '/sign-up-business' },
  signUpInvestor: { title: 'Sign Up for Investor', description: 'Sign Up for Investor.', path: '/sign-up' },
  signUpNext: { title: 'Sign Up Continued', description: 'Sign Up Continued.', path: '/sign-up-next' },
  verifyProfile: { title: 'Verify Profile', description: 'Verify Profile.', path: '/kyc/company/1' },
  businessVerifyProfile: { title: 'Verify Profile', description: 'Verify Profile.', path: '/kyc/business' },
  viewDocuments: { title: 'View Documents', description: 'View Documents.', path: '/view-documents' },
  viewDocument: { title: 'View Document', description: 'View Document.', path: '/view-document' },
  adminViewDocument: { title: 'View Document', description: 'View Document.', path: '/admin/view-document' },
  viewFile: { title: 'View File', description: 'View File.', path: '/view-file' },
  watchList: { title: 'Wishlist', description: 'Wishlist.', path: '/wishlist' },
  kyc: { title: 'KYC', description: 'KYC.', path: '/admin/kyc' },
  adminInvestors: { title: 'Investors', description: 'Investors.', path: '/admin/investors' },
  adminDeals: { title: 'Deals', description: 'Deals.', path: '/admin/deals' },
  adminSignIn: { title: 'Sign In', description: 'Sign In.', path: '/admin' },
  adminDashboard: { title: 'Dashboard', description: 'Dashboard.', path: '/admin/dashboard' },
  adminBusinessAngels: { title: 'Business Angels', description: 'Business Angels.', path: '/admin/business-angels' },
  adminCalendar: { title: 'Calendar', description: 'Calendar.', path: '/admin/calendar' },
  kycDetails: { title: 'KYC Details', description: 'KYC Details.', path: '/admin/kyc-details' },
  ba: { title: 'Business Angel', description: 'Business Angel.', path: '/admin/business-angel' },
  investor: { title: 'Investor', description: 'Investor.', path: '/admin/investor' }
}
