import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const slice = createSlice({
  name: 'approveDealDocuments',
  initialState,
  reducers: {
    setApproveDealDocuments: (state, { payload }) => {
      state.value = state.value
        ? { ...state.value, ...payload }
        : { ...payload }
    }
  }
})

export const { setApproveDealDocuments } = slice.actions

export default slice.reducer
